<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">
        {{ inputs.language === 'en' ? 'Experiment 6: Question 5' : 'Expérience 6: Question 5' }}
      </h2>

      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'What is the role of dry ice?'
            : 'Quel est le rôle de la neige carbonique (glace sèche)?'
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab6Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Catalyst', value: 'catalyst'},
        {text: 'Electrophile', value: 'electrophile'},
        {text: 'Nucleophile', value: 'nucleophile'},
        {text: 'Solvent', value: 'solvent'},
        {text: 'To keep the reaction mixture cold', value: 'keepCold'},
        {text: 'To quench the Grignard reaction', value: 'quench'},
      ],
      optionsFr: [
        {text: 'Catalyseur', value: 'catalyst'},
        {text: 'Électrophile', value: 'electrophile'},
        {text: 'Nucléophile', value: 'nucleophile'},
        {text: 'Solvant', value: 'solvent'},
        {text: 'De garder le mélange réactionnel froid', value: 'keepCold'},
        {text: 'De neutraliser la réaction de Grignard', value: 'quench'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
